import { handleQueryResolve } from '../utils';

export default function(){
    return this.query(`
        SELECT 
            PatientID
        FROM 
            Fillware.dbo.Patient 
        WHERE
            PatientID = 1
    `)
    .then(handleQueryResolve);
}